import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon from "~/assets/images/favicon.png";

export const Seo = ({
  description,
  lang,
  meta,
  title,
  structuredData,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const META = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      name: "og:title",
      content: title,
    },
    {
      name: "og:description",
      content: metaDescription,
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: site.siteMetadata?.author || "",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
    {
      name: "theme-color",
      content: "#7A8CFF",
      media: "(prefers-color-scheme: light)",
    },
    {
      name: "theme-color",
      content: "#5662B3",
      media: "(prefers-color-scheme: dark)",
    },
  ].concat(meta)

  return (
    <Helmet
      titleTemplate={title ? `%s | ${defaultTitle}` : null}
      htmlAttributes={{ lang }}
    >
      <title>{title || defaultTitle}</title>

      {META.map(({ name, content, media }, index) => (
        <meta key={`${name}:${index}`} {...{ name, content, media }} />
      ))}

      <link
        rel="icon"
        type="image/png"
        href={favicon}
      />

      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};
Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
